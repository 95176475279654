import Vue from 'vue'
import VueRouter from 'vue-router'

// import store from '../store'

Vue.use(VueRouter)

function loadView (view) {
  return () => import(`@/components/${view}.vue`)
}

Vue.router = new VueRouter({
  hashbang: false,
  // mode: 'history',
  routes:
    [
      {
        path: '/',
        name: 'Index',
        component: loadView('Index'),
        meta: {
          requiresAuth: true
        },
        children: [
          {
            path: '/Optimization',
            redirect: '/Optimization/Campaigns'
          },
          {
            path: '/Optimization/Campaigns',
            name: 'Campaigns',
            component: loadView('optimization/Campaigns'),
            meta: {
              requiresAuth: true
            },
            children: [
              {
                path: ':campaignId/edit',
                name: 'Edit',
                component: loadView('optimization/Campaigns'),
                meta: {
                  requiresAuth: true
                }
              },
            ]
          },
          // {
          //   path: '/Optimization/Campaigns',
          //   name: 'Campaigns',
          //   component: loadView('optimization/Campaigns'),
          //   meta: {
          //     requiresAuth: true
          //   }
          // },
          {
            path: '/Spending/Total',
            name: 'SpendingTotal',
            component: loadView('Spending/Total'),
            meta: {
              requiresAuth: true
            }
          },
          {
            path: '/Spending/Campaigns',
            name: 'SpendingCampaigns',
            component: loadView('Spending/Campaigns'),
            meta: {
              requiresAuth: true
            }
          },
          {
            path: '/Sources/:section',
            name: 'Sources',
            component: loadView('Sources/index'),
            meta: {
              requiresAuth: true
            }
          },
          {
            path: '/Earnings/Networks',
            name: 'EarningsNetworks',
            component: loadView('Earnings/EarningsNetworks'),
            meta: {
              requiresAuth: true
            }
          },
          {
            path: '/Optimization/errorInUrl',
            name: 'ErrorInUrl',
            component: loadView('optimization/ErrorInUrl'),
            meta: {
              requiresAuth: true
            }
          },
          {
            path: '/Optimization/Statistic',
            name: 'OptimizationStatistic',
            component: loadView('optimization/Statistic'),
            meta: {
              requiresAuth: true
            }
          },
          // {
          //   path: '/DSP',
          //   name: 'DSP',
          //   component: loadView('DSP/Index'),
          //   meta: {
          //     requiresAuth: true
          //   }
          // },
          // {
          //   path: '/DSP*',
          //   name: 'DSP',
          //   meta: {
          //     requiresAuth: true
          //   },
          //   // component: loadView('DSP/Statistic'),
          //   children: [
          //
          //   ]
          // }
          {
            path: '/DSP/Statistic',
            name: 'DSPStatistic',
            component: loadView('DSP/Statistic'),
            meta: {
              requiresAuth: true
            }
          },
          {
            path: '/DSP/Teasers',
            redirect: '/DSP/Teasers/Mgid',
          },
          {
            path: '/DSP/Teasers/:section',
            name: 'DSPTeasers',
            component: loadView('DSP/Teasers/Teasers'),
            meta: {
              requiresAuth: true
            }
          },
          {
            path: '/AAA/Accounts',
            name: 'AAAAccounts',
            component: loadView('AAA/Accounts'),
            meta: {
              requiresAuth: false
            }
          },
          {
            path: '/API/Flow',
            name: 'FlowTable',
            component: loadView('API/FlowTable'),
            meta: {
              requiresAuth: false
            }
          },
          {
            path: '/Tasks',
            name: 'Tasks',
            component: loadView('Tasks/Tasks'),
            meta: {
              requiresAuth: false
            }
          },
          {
            path: '/AffNets',
            name: 'AffNets',
            component: loadView('AffNets/Index'),
            meta: {
              requiresAuth: false
            },
            children: [
              {
                path: ':network/:account',
                name: 'AffNetwork',
                component: loadView('AffNets/Index'),
                meta: {
                  requiresAuth: false
                },
              },
            ]
          },
        ]
      },
      {
        path: '/login',
        name: 'AuthLogin',
        component: loadView('AAA/Login'),
        meta: {
          requiresAuth: false
        }
      },
      {
        path: '/OTL',
        name: 'OTL',
        component: loadView('AAA/OTL'),
        meta: {
          requiresAuth: false
        }
      },

      // {
      //   path: '/social',
      //   name: 'auth-social',
      //   component: loadView('auth/Social'),
      // },
      // {
      //   path: '/login/:type',
      //   name: 'auth-login-social',
      //   component: loadView('auth/Social'),
      // },
      // {
      //   path: '/register',
      //   name: 'auth-register',
      //   component: loadView('auth/Register'),
      //   meta: {
      //     auth: false
      //   }
      // },
      // {
      //   path: '/user',
      //   component: loadView('user/Index'),
      //   meta: {
      //     auth: true
      //   },
      //   children: [
      //     {
      //       path: '',
      //       name: 'user-landing',
      //       redirect: {
      //         name: 'user-account'
      //       }
      //     },
      //     {
      //       path: 'account',
      //       name: 'user-account',
      //       component: loadView('user/Account')
      //     },
      //     {
      //       path: 'unimpersonate',
      //       name: 'user-unimpersonate',
      //       component: loadView('user/Unimpersonate')
      //     },
      //     {
      //       path: 'logout',
      //       name: 'user-logout',
      //       component: loadView('user/Logout')
      //     }
      //   ]
      // },
      // {
      //   path: '/admin',
      //   component: loadView('admin/Index'),
      //   meta: {
      //     auth: 'admin'
      //   },
      //   children: [{
      //     path: '/',
      //     name: 'admin-landing',
      //     redirect: {
      //       name: 'admin-users'
      //     }
      //   }, {
      //     path: 'users',
      //     name: 'admin-users',
      //     component: loadView('admin/Users')
      //   }]
      // },
      // {
      //   path: '/',
      //   component: loadView('error/Index'),
      //   children: [{
      //     path: '403',
      //     name: 'error-403',
      //     component: loadView('error/403'),
      //   },
      //     {
      //       path: '*',
      //       name: 'error-404',
      //       component: loadView('error/404'),
      //     }]
      // }
    ]
})
//
// const ifNotAuthenticated = (to, from, next) => {
//   if (!store.getters.isAuthenticated) {
//     next()
//     return
//   }
//   next('/')
// }
//
// const ifAuthenticated = (to, from, next) => {
//   if (store.getters.isAuthenticated) {
//     next()
//     return
//   }
//   next('/login')
// }

Vue.router.beforeEach((to, from, next) => {
  // window.console.log('beforeEach', to, from)
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // window.console.log('beforeEach matched', store.getters['auth/isAuthenticated'])
    // if (store.getters['auth/isAuthenticated']) {
    if (localStorage.getItem('user-token') === null) {
      next('/login')
      return
    }
    next()

    // if (localStorage.getItem('jwt') == null) {
    //   next({
    //     path: '/login',
    //     params: {nextUrl: to.fullPath}
    //   })
    // } else {
    //   let user = JSON.parse(localStorage.getItem('user'))
    //   if (to.matched.some(record => record.meta.is_admin)) {
    //     if (user.is_admin === 1) {
    //       next()
    //     } else {
    //       next({name: 'userboard'})
    //     }
    //   } else {
    //     next()
    //   }
    // }
  } else {
    // window.console.log('beforeEach isAuthenticated:', store.getters['auth/isAuthenticated'])
    // window.console.log('store.getters.authStatus.isAuthenticated', store.getters['auth/isAuthenticated'], to.name)
    // if (store.getters['auth/isAuthenticated'] && to.name === 'auth-login') {
    if (localStorage.getItem('user-token') !== null && to.name === 'AuthLogin') {
      next('/')
      return
    }
    next()
  }
})

export default Vue.router