const moduleName = 'iface'

export const DRAWER_LEFT_SWITCH = 'DRAWER_LEFT_SWITCH'
export const DRAWER_LEFT_ON = 'DRAWER_LEFT_SWITCH_ON'
export const DRAWER_LEFT_OFF = 'DRAWER_LEFT_SWITCH_OFF'

export const IFACE_DRAWER_LEFT_SWITCH = moduleName + '/' + DRAWER_LEFT_SWITCH
export const IFACE_DRAWER_LEFT_ON = moduleName + '/' + DRAWER_LEFT_ON
export const IFACE_DRAWER_LEFT_OFF = moduleName + '/' + DRAWER_LEFT_OFF

export const DRAWER_RIGHT_SWITCH = 'DRAWER_RIGHT_SWITCH'
export const DRAWER_RIGHT_ON = 'DRAWER_RIGHT_SWITCH_ON'
export const DRAWER_RIGHT_OFF = 'DRAWER_RIGHT_SWITCH_OFF'

export const IFACE_DRAWER_RIGHT_SWITCH = moduleName + '/' + DRAWER_RIGHT_SWITCH
export const IFACE_DRAWER_RIGHT_ON = moduleName + '/' + DRAWER_RIGHT_ON
export const IFACE_DRAWER_RIGHT_OFF = moduleName + '/' + DRAWER_RIGHT_OFF

export const ITEMS_PER_PAGE = 'ITEMS_PER_PAGE'
export const IFACE_ITEMS_PER_PAGE = moduleName + '/' + ITEMS_PER_PAGE

export const SPENDING_LAST_UPDATE = 'SPENDING_LAST_UPDATE'
export const IFACE_SPENDING_LAST_UPDATE = moduleName + '/' + SPENDING_LAST_UPDATE

// export const SPENDING_CAMPAIGNS_LAST_UPDATE = 'SPENDING_CAMPAIGNS_LAST_UPDATE'
// export const IFACE_SPENDING_CAMPAIGNS_LAST_UPDATE = moduleName + '/' + SPENDING_CAMPAIGNS_LAST_UPDATE

export const URL_CHECKER_LAST_UPDATE = 'URL_CHECKER_LAST_UPDATE'
export const IFACE_URL_CHECKER_LAST_UPDATE = moduleName + '/' + URL_CHECKER_LAST_UPDATE

// export const PARENT_OFFER_ITEMS = 'PARENT_OFFER_ITEMS'
// export const IFACE_PARENT_OFFER_ITEMS = moduleName + '/' + PARENT_OFFER_ITEMS

// export const STAS_OFR_ITEMS = 'STAS_OFR_ITEMS'
// export const IFACE_STAS_OFR_ITEMS = moduleName + '/' + STAS_OFR_ITEMS

export const PARENT_OFFER_VALUE = 'PARENT_OFFER_VALUE'
export const IFACE_PARENT_OFFER_VALUE = moduleName + '/' + PARENT_OFFER_VALUE

export const STAS_OFR_VALUE = 'STAS_OFR_VALUE'
export const IFACE_STAS_OFR_VALUE = moduleName + '/' + STAS_OFR_VALUE

export const CAB_ITEMS = 'CAB_ITEMS'
export const IFACE_CAB_ITEMS = moduleName + '/' + CAB_ITEMS

export const CAB_VALUE = 'CAB_VALUE'
export const IFACE_CAB_VALUE = moduleName + '/' + CAB_VALUE

export const OPTIMIZATION_ADD_ITEM_WINDOW_ON = 'OPTIMIZATION_ADD_ITEM_WINDOW_ON'
export const OPTIMIZATION_ADD_ITEM_WINDOW_OFF = 'OPTIMIZATION_ADD_ITEM_WINDOW_OFF'
export const OPTIMIZATION_ADD_ITEM_WINDOW_SWITCH = 'OPTIMIZATION_ADD_ITEM_WINDOW_SWITCH'

export const IFACE_OPTIMIZATION_ADD_ITEM_WINDOW_ON = moduleName + '/' + OPTIMIZATION_ADD_ITEM_WINDOW_ON
export const IFACE_OPTIMIZATION_ADD_ITEM_WINDOW_OFF = moduleName + '/' + OPTIMIZATION_ADD_ITEM_WINDOW_OFF
export const IFACE_OPTIMIZATION_ADD_ITEM_WINDOW_SWITCH = moduleName + '/' + OPTIMIZATION_ADD_ITEM_WINDOW_SWITCH

export const OPTIMIZATION_ADD_NEW_TASK_ON = 'OPTIMIZATION_ADD_NEW_TASK_ON'
export const OPTIMIZATION_ADD_NEW_TASK_OFF = 'OPTIMIZATION_ADD_NEW_TASK_OFF'
export const OPTIMIZATION_ADD_NEW_TASK_SWITCH = 'OPTIMIZATION_ADD_NEW_TASK_SWITCH'

export const IFACE_OPTIMIZATION_ADD_NEW_TASK_ON = moduleName + '/' + OPTIMIZATION_ADD_NEW_TASK_ON
export const IFACE_OPTIMIZATION_ADD_NEW_TASK_OFF = moduleName + '/' + OPTIMIZATION_ADD_NEW_TASK_OFF
export const IFACE_OPTIMIZATION_ADD_NEW_TASK_SWITCH = moduleName + '/' + OPTIMIZATION_ADD_NEW_TASK_SWITCH

export const OPTIMIZATION_REFRESH_ON = 'OPTIMIZATION_REFRESH_ON'
export const OPTIMIZATION_REFRESH_OFF = 'OPTIMIZATION_REFRESH_OFF'

export const IFACE_OPTIMIZATION_REFRESH_ON = moduleName + '/' + OPTIMIZATION_REFRESH_ON
export const IFACE_OPTIMIZATION_REFRESH_OFF = moduleName + '/' + OPTIMIZATION_REFRESH_OFF

export const OPTIMIZATION_TABLE_LOADING_ON = 'OPTIMIZATION_TABLE_LOADING_ON'
export const OPTIMIZATION_TABLE_LOADING_OFF = 'OPTIMIZATION_TABLE_LOADING_OFF'

export const IFACE_OPTIMIZATION_TABLE_LOADING_ON = moduleName + '/' + OPTIMIZATION_TABLE_LOADING_ON
export const IFACE_OPTIMIZATION_TABLE_LOADING_OFF = moduleName + '/' + OPTIMIZATION_TABLE_LOADING_OFF

export const AFFNETS_REFRESH_ON = 'AFFNETS_REFRESH_ON'
export const AFFNETS_REFRESH_OFF = 'AFFNETS_REFRESH_OFF'

export const IFACE_AFFNETS_REFRESH_ON = moduleName + '/' + AFFNETS_REFRESH_ON
export const IFACE_AFFNETS_REFRESH_OFF = moduleName + '/' + AFFNETS_REFRESH_OFF

export const AFFNETS_TABLE_LOADING_ON = 'AFFNETS_TABLE_LOADING_ON'
export const AFFNETS_TABLE_LOADING_OFF = 'AFFNETS_TABLE_LOADING_OFF'

export const IFACE_AFFNETS_TABLE_LOADING_ON = moduleName + '/' + AFFNETS_TABLE_LOADING_ON
export const IFACE_AFFNETS_TABLE_LOADING_OFF = moduleName + '/' + AFFNETS_TABLE_LOADING_OFF


export const SOURCE_CAMPAIGNS_REFRESH_ON = 'AFFNETS_REFRESH_ON'
export const SOURCE_CAMPAIGNS_REFRESH_OFF = 'AFFNETS_REFRESH_OFF'

export const IFACE_SOURCE_CAMPAIGNS_REFRESH_ON = moduleName + '/' + SOURCE_CAMPAIGNS_REFRESH_ON
export const IFACE_SOURCE_CAMPAIGNS_REFRESH_OFF = moduleName + '/' + SOURCE_CAMPAIGNS_REFRESH_OFF

export const SOURCE_CAMPAIGNS_FORCE_REFRESH_ON = 'SOURCE_CAMPAIGNS_FORCE_REFRESH_ON'
export const SOURCE_CAMPAIGNS_FORCE_REFRESH_OFF = 'SOURCE_CAMPAIGNS_FORCE_REFRESH_OFF'

export const IFACE_SOURCE_CAMPAIGNS_FORCE_REFRESH_ON = moduleName + '/' + SOURCE_CAMPAIGNS_FORCE_REFRESH_ON
export const IFACE_SOURCE_CAMPAIGNS_FORCE_REFRESH_OFF = moduleName + '/' + SOURCE_CAMPAIGNS_FORCE_REFRESH_OFF

export const SOURCE_CAMPAIGNS_ALLOW_REFRESH_ON = 'SOURCE_CAMPAIGNS_ALLOW_REFRESH_ON'
export const SOURCE_CAMPAIGNS_ALLOW_REFRESH_OFF = 'SOURCE_CAMPAIGNS_ALLOW_REFRESH_OFF'

export const IFACE_SOURCE_CAMPAIGNS_ALLOW_REFRESH_ON = moduleName + '/' + SOURCE_CAMPAIGNS_ALLOW_REFRESH_ON
export const IFACE_SOURCE_CAMPAIGNS_ALLOW_REFRESH_OFF = moduleName + '/' + SOURCE_CAMPAIGNS_ALLOW_REFRESH_OFF


export const SOURCE_CAMPAIGNS_LOADING_ON = 'SOURCE_CAMPAIGNS_LOADING_ON'
export const SOURCE_CAMPAIGNS_LOADING_OFF = 'SOURCE_CAMPAIGNS_LOADING_OFF'

export const IFACE_SOURCE_CAMPAIGNS_LOADING_ON = moduleName + '/' + SOURCE_CAMPAIGNS_LOADING_ON
export const IFACE_SOURCE_CAMPAIGNS_LOADING_OFF = moduleName + '/' + SOURCE_CAMPAIGNS_LOADING_OFF



export const AFFNETS_SET_FINALIZEDDATES = 'AFFNETS_SET_FINALIZEDDATES'
export const IFACE_AFFNETS_SET_FINALIZEDDATES = moduleName + '/' + AFFNETS_SET_FINALIZEDDATES

export const OPTIMIZATION_TABLE_MASS_ACTION_ENABLE_ON = 'OPTIMIZATION_TABLE_MASS_ACTION_ENABLE_ON'
export const OPTIMIZATION_TABLE_MASS_ACTION_ENABLE_OFF = 'OPTIMIZATION_TABLE_MASS_ACTION_ENABLE_OFF'

export const IFACE_OPTIMIZATION_TABLE_MASS_ACTION_ENABLE_ON = moduleName + '/' + OPTIMIZATION_TABLE_MASS_ACTION_ENABLE_ON
export const IFACE_OPTIMIZATION_TABLE_MASS_ACTION_ENABLE_OFF = moduleName + '/' + OPTIMIZATION_TABLE_MASS_ACTION_ENABLE_OFF

export const OPTIMIZATION_TABLE_MASS_ACTION_ALLOW_ON = 'OPTIMIZATION_TABLE_MASS_ACTION_ALLOW_ON'
export const OPTIMIZATION_TABLE_MASS_ACTION_ALLOW_OFF = 'OPTIMIZATION_TABLE_MASS_ACTION_ALLOW_OFF'

export const IFACE_OPTIMIZATION_TABLE_MASS_ACTION_ALLOW_ON = moduleName + '/' + OPTIMIZATION_TABLE_MASS_ACTION_ALLOW_ON
export const IFACE_OPTIMIZATION_TABLE_MASS_ACTION_ALLOW_OFF = moduleName + '/' + OPTIMIZATION_TABLE_MASS_ACTION_ALLOW_OFF

export const OPTIMIZATION_TABLE_MASS_ACTION_ON = 'OPTIMIZATION_TABLE_MASS_ACTION_ON'
export const OPTIMIZATION_TABLE_MASS_ACTION_OFF = 'OPTIMIZATION_TABLE_MASS_ACTION_OFF'

export const IFACE_OPTIMIZATION_TABLE_MASS_ACTION_ON = moduleName + '/' + OPTIMIZATION_TABLE_MASS_ACTION_ON
export const IFACE_OPTIMIZATION_TABLE_MASS_ACTION_OFF = moduleName + '/' + OPTIMIZATION_TABLE_MASS_ACTION_OFF


export const OPTIMIZATION_TABLE_MASS_ACTION_LOADING_ON = 'OPTIMIZATION_TABLE_MASS_ACTION_LOADING_ON'
export const OPTIMIZATION_TABLE_MASS_ACTION_LOADING_OFF = 'OPTIMIZATION_TABLE_MASS_ACTION_LOADING_OFF'

export const IFACE_OPTIMIZATION_TABLE_MASS_ACTION_LOADING_ON = moduleName + '/' + OPTIMIZATION_TABLE_MASS_ACTION_LOADING_ON
export const IFACE_OPTIMIZATION_TABLE_MASS_ACTION_LOADING_OFF = moduleName + '/' + OPTIMIZATION_TABLE_MASS_ACTION_LOADING_OFF

export const SPENDING_CAMPAIGNS_REFRESH_ON = 'SPENDING_CAMPAIGNS_REFRESH_ON'
export const SPENDING_CAMPAIGNS_REFRESH_OFF = 'SPENDING_CAMPAIGNS_REFRESH_OFF'

export const IFACE_SPENDING_CAMPAIGNS_REFRESH_ON = moduleName + '/' + SPENDING_CAMPAIGNS_REFRESH_ON
export const IFACE_SPENDING_CAMPAIGNS_REFRESH_OFF = moduleName + '/' + SPENDING_CAMPAIGNS_REFRESH_OFF

export const SPENDING_CAMPAIGNS_TABLE_LOADING_ON = 'SPENDING_CAMPAIGNS_TABLE_LOADING_ON'
export const SPENDING_CAMPAIGNS_TABLE_LOADING_OFF = 'SPENDING_CAMPAIGNS_TABLE_LOADING_OFF'

export const IFACE_SPENDING_CAMPAIGNS_TABLE_LOADING_ON = moduleName + '/' + SPENDING_CAMPAIGNS_TABLE_LOADING_ON
export const IFACE_SPENDING_CAMPAIGNS_TABLE_LOADING_OFF = moduleName + '/' + SPENDING_CAMPAIGNS_TABLE_LOADING_OFF

export const OPTIMIZATION_ON_MODERATION_ON = 'OPTIMIZATION_ON_MODERATION_ON'
export const OPTIMIZATION_ON_MODERATION_OFF = 'OPTIMIZATION_ON_MODERATION_OFF'

export const IFACE_OPTIMIZATION_ON_MODERATION_ON = moduleName + '/' + OPTIMIZATION_ON_MODERATION_ON
export const IFACE_OPTIMIZATION_ON_MODERATION_OFF = moduleName + '/' + OPTIMIZATION_ON_MODERATION_OFF

export const OPTIMIZATION_FILTER_AUTOPRICE_ON = 'OPTIMIZATION_FILTER_AUTOPRICE_ON'
export const OPTIMIZATION_FILTER_AUTOPRICE_OFF = 'OPTIMIZATION_FILTER_AUTOPRICE_OFF'

export const IFACE_OPTIMIZATION_FILTER_AUTOPRICE_ON = moduleName + '/' + OPTIMIZATION_FILTER_AUTOPRICE_ON
export const IFACE_OPTIMIZATION_FILTER_AUTOPRICE_OFF = moduleName + '/' + OPTIMIZATION_FILTER_AUTOPRICE_OFF

export const OPTIMIZATION_FILTER_OPTIOFF_ON = 'OPTIMIZATION_FILTER_OPTIOFF_ON'
export const OPTIMIZATION_FILTER_OPTIOFF_OFF = 'OPTIMIZATION_FILTER_OPTIOFF_OFF'

export const IFACE_OPTIMIZATION_FILTER_OPTIOFF_ON = moduleName + '/' + OPTIMIZATION_FILTER_OPTIOFF_ON
export const IFACE_OPTIMIZATION_FILTER_OPTIOFF_OFF = moduleName + '/' + OPTIMIZATION_FILTER_OPTIOFF_OFF

// export const RUNNING_BACKDATING_UPDATE_SPENT_ON = 'RUNNING_BACKDATING_UPDATE_SPENT_ON'
export const RUNNING_BACKDATING_UPDATE_SPENT_OFF = 'RUNNING_BACKDATING_UPDATE_SPENT_OFF'

// export const IFACE_RUNNING_BACKDATING_UPDATE_SPENT_ON = moduleName + '/' + RUNNING_BACKDATING_UPDATE_SPENT_ON
export const IFACE_RUNNING_BACKDATING_UPDATE_SPENT_OFF = moduleName + '/' + RUNNING_BACKDATING_UPDATE_SPENT_OFF

export const SET_RUNNING_BACKDATING_UPDATE_SPENT_STATUS = 'SET_RUNNING_BACKDATING_UPDATE_SPENT_STATUS'

export const IFACE_SET_RUNNING_BACKDATING_UPDATE_SPENT_STATUS = moduleName + '/' + SET_RUNNING_BACKDATING_UPDATE_SPENT_STATUS

export const DSP_TEASERS_ON_MODERATION_ON = 'DSP_TEASERS_ON_MODERATION_ON'
export const DSP_TEASERS_ON_MODERATION_OFF = 'DSP_TEASERS_ON_MODERATION_OFF'

export const IFACE_DSP_TEASERS_ON_MODERATION_ON = moduleName + '/' + DSP_TEASERS_ON_MODERATION_ON
export const IFACE_DSP_TEASERS_ON_MODERATION_OFF = moduleName + '/' + DSP_TEASERS_ON_MODERATION_OFF

export const DSP_TEASERS_ADD_ITEM_WINDOW_ON = 'DSP_TEASERS_ADD_ITEM_WINDOW_ON'
export const DSP_TEASERS_ADD_ITEM_WINDOW_OFF = 'DSP_TEASERS_ADD_ITEM_WINDOW_OFF'
export const DSP_TEASERS_ADD_ITEM_WINDOW_SWITCH = 'DSP_TEASERS_ADD_ITEM_WINDOW_SWITCH'

export const IFACE_DSP_TEASERS_ADD_ITEM_WINDOW_ON = moduleName + '/' + DSP_TEASERS_ADD_ITEM_WINDOW_ON
export const IFACE_DSP_TEASERS_ADD_ITEM_WINDOW_OFF = moduleName + '/' + DSP_TEASERS_ADD_ITEM_WINDOW_OFF
export const IFACE_DSP_TEASERS_ADD_ITEM_WINDOW_SWITCH = moduleName + '/' + DSP_TEASERS_ADD_ITEM_WINDOW_SWITCH


export const AFFNETS_ADD_ITEM_WINDOW_ON = 'AFFNETS_ADD_ITEM_WINDOW_ON'
export const AFFNETS_ADD_ITEM_WINDOW_OFF = 'AFFNETS_ADD_ITEM_WINDOW_OFF'
export const AFFNETS_ADD_ITEM_WINDOW_SWITCH = 'AFFNETS_ADD_ITEM_WINDOW_SWITCH'

export const IFACE_AFFNETS_ADD_ITEM_WINDOW_ON = moduleName + '/' + AFFNETS_ADD_ITEM_WINDOW_ON
export const IFACE_AFFNETS_ADD_ITEM_WINDOW_OFF = moduleName + '/' + AFFNETS_ADD_ITEM_WINDOW_OFF
export const IFACE_AFFNETS_ADD_ITEM_WINDOW_SWITCH = moduleName + '/' + AFFNETS_ADD_ITEM_WINDOW_SWITCH

export const DSP_TEASERS_REFRESH_ON = 'DSP_TEASERS_REFRESH_ON'
export const DSP_TEASERS_REFRESH_OFF = 'DSP_TEASERS_REFRESH_OFF'

export const IFACE_DSP_TEASERS_REFRESH_ON = moduleName + '/' + DSP_TEASERS_REFRESH_ON
export const IFACE_DSP_TEASERS_REFRESH_OFF = moduleName + '/' + DSP_TEASERS_REFRESH_OFF

export const DSP_TEASERS_ALLOW_MASS_CHANGES_ON = 'DSP_TEASERS_ALLOW_MASS_CHANGES_ON'
export const DSP_TEASERS_ALLOW_MASS_CHANGES_OFF = 'DSP_TEASERS_ALLOW_MASS_CHANGES_OFF'

export const IFACE_DSP_TEASERS_ALLOW_MASS_CHANGES_ON = moduleName + '/' + DSP_TEASERS_ALLOW_MASS_CHANGES_ON
export const IFACE_DSP_TEASERS_ALLOW_MASS_CHANGES_OFF = moduleName + '/' + DSP_TEASERS_ALLOW_MASS_CHANGES_OFF

export const DSP_TEASERS_MASS_CHANGES_ON = 'DSP_TEASERS_MASS_CHANGES_ON'
export const DSP_TEASERS_MASS_CHANGES_OFF = 'DSP_TEASERS_MASS_CHANGES_OFF'

export const IFACE_DSP_TEASERS_MASS_CHANGES_ON = moduleName + '/' + DSP_TEASERS_MASS_CHANGES_ON
export const IFACE_DSP_TEASERS_MASS_CHANGES_OFF = moduleName + '/' + DSP_TEASERS_MASS_CHANGES_OFF

export const DSP_TEASERS_TABLE_LOADING_ON = 'DSP_TEASERS_TABLE_LOADING_ON'
export const DSP_TEASERS_TABLE_LOADING_OFF = 'DSP_TEASERS_TABLE_LOADING_OFF'

export const IFACE_DSP_TEASERS_TABLE_LOADING_ON = moduleName + '/' + DSP_TEASERS_TABLE_LOADING_ON
export const IFACE_DSP_TEASERS_TABLE_LOADING_OFF = moduleName + '/' + DSP_TEASERS_TABLE_LOADING_OFF

export const DST_SET_DEP = 'DST_SET_DEP'
export const IFACE_DST_SET_DEP = moduleName + '/' + 'DST_SET_DEP'

export const DST_SET_DEPS = 'DST_SET_DEPS'
export const IFACE_DST_SET_DEPS = moduleName + '/' + 'DST_SET_DEPS'

export const DSP_SHOW_ONLY_EMPTY_TAGS_ON = 'DSP_SHOW_ONLY_EMPTY_TAGS_ON'
export const DSP_SHOW_ONLY_EMPTY_TAGS_OFF = 'DSP_SHOW_ONLY_EMPTY_TAGS_OFF'

export const IFACE_DSP_SHOW_ONLY_EMPTY_TAGS_ON = moduleName + '/' + DSP_SHOW_ONLY_EMPTY_TAGS_ON
export const IFACE_DSP_SHOW_ONLY_EMPTY_TAGS_OFF = moduleName + '/' + DSP_SHOW_ONLY_EMPTY_TAGS_OFF

export const DSP_IS_VISIBLE_STAT_ON = 'DSP_IS_VISIBLE_STAT_ON'
export const DSP_IS_VISIBLE_STAT_OFF = 'DSP_IS_VISIBLE_STAT_OFF'

export const IFACE_DSP_IS_VISIBLE_STAT_ON = moduleName + '/' + DSP_IS_VISIBLE_STAT_ON
export const IFACE_DSP_IS_VISIBLE_STAT_OFF = moduleName + '/' + DSP_IS_VISIBLE_STAT_OFF


export const DSP_IS_VISIBLE_STAT_FROM_DSP_ON = 'DSP_IS_VISIBLE_STAT_FROM_DSP_ON'
export const DSP_IS_VISIBLE_STAT_FROM_DSP_OFF = 'DSP_IS_VISIBLE_STAT_FROM_DSP_OFF'

export const IFACE_DSP_IS_VISIBLE_STAT_FROM_DSP_ON = moduleName + '/' + DSP_IS_VISIBLE_STAT_FROM_DSP_ON
export const IFACE_DSP_IS_VISIBLE_STAT_FROM_DSP_OFF = moduleName + '/' + DSP_IS_VISIBLE_STAT_FROM_DSP_OFF



export const DSP_TEASERS_SOURCES_SET = 'DSP_TEASERS_SOURCES_SET'

export const IFACE_DSP_TEASERS_SOURCES_SET = moduleName + '/' + DSP_TEASERS_SOURCES_SET

export const EARNING_ADD_STATISTIC_WINDOW_ON = 'EARNING_ADD_STATISTIC_WINDOW_ON'
export const EARNING_ADD_STATISTIC_WINDOW_OFF = 'EARNING_ADD_STATISTIC_WINDOW_OFF'
export const EARNING_ADD_STATISTIC_WINDOW_SWITCH = 'EARNING_ADD_STATISTIC_WINDOW_SWITCH'

export const IFACE_EARNING_ADD_STATISTIC_WINDOW_ON = moduleName + '/' + EARNING_ADD_STATISTIC_WINDOW_ON
export const IFACE_EARNING_ADD_STATISTIC_WINDOW_OFF = moduleName + '/' + EARNING_ADD_STATISTIC_WINDOW_OFF
export const IFACE_EARNING_ADD_STATISTIC_WINDOW_SWITCH = moduleName + '/' + EARNING_ADD_STATISTIC_WINDOW_SWITCH

export const API_ADD_ITEM_WINDOW_ON = 'API_ADD_ITEM_WINDOW_ON'
export const API_ADD_ITEM_WINDOW_OFF = 'API_ADD_ITEM_WINDOW_OFF'
export const API_ADD_ITEM_WINDOW_SWITCH = 'API_ADD_ITEM_WINDOW_SWITCH'

export const IFACE_API_ADD_ITEM_WINDOW_ON = moduleName + '/' + API_ADD_ITEM_WINDOW_ON
export const IFACE_API_ADD_ITEM_WINDOW_OFF = moduleName + '/' + API_ADD_ITEM_WINDOW_OFF
export const IFACE_API_ADD_ITEM_WINDOW_SWITCH = moduleName + '/' + API_ADD_ITEM_WINDOW_SWITCH

export const FLOW_REFRESH_ON = 'FLOW_REFRESH_ON'
export const FLOW_REFRESH_OFF = 'FLOW_REFRESH_OFF'

export const IFACE_FLOW_REFRESH_ON = moduleName + '/' + FLOW_REFRESH_ON
export const IFACE_FLOW_REFRESH_OFF = moduleName + '/' + FLOW_REFRESH_OFF

export const FLOW_TABLE_LOADING_ON = 'FLOW_TABLE_LOADING_ON'
export const FLOW_TABLE_LOADING_OFF = 'FLOW_TABLE_LOADING_OFF'

export const IFACE_FLOW_TABLE_LOADING_ON = moduleName + '/' + FLOW_TABLE_LOADING_ON
export const IFACE_FLOW_TABLE_LOADING_OFF = moduleName + '/' + FLOW_TABLE_LOADING_OFF


